<template>
  <div class="noreg-examination">
    <div class="title-and-buttons">
      <div class="title">
        <h1>Издаване на направление без регистрация</h1>
        <p class="accent">Резултатите ще бъдат видими през профил на пациента</p>
      </div>
    </div>
    <ExaminationPanel v-if="isExaminationPanelOpen" @close-panel="examinationPanelClosed" />
  </div>
</template>

<script>
import ExaminationPanel from '@/views/panels/ExaminationPanel';
export default {
  components: {
    ExaminationPanel
  },
  data() {
    return {
      isExaminationPanelOpen: false
    };
  },
  methods: {
    examinationPanelClosed() {
      this.isExaminationPanelOpen = false;
    }
  },
  mounted() {
    // setTimeout(() => {
      this.isExaminationPanelOpen = true;
    // }, 1000);
  }
};
</script>

<style>
</style>